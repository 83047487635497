.download-link img {
  height: 60px;
  margin: 15px;
}

.download-link.disabled {
  opacity: 0.5 !important;
  cursor: no-drop;
}

.download-link:hover {
  opacity: 0.8;
}

/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Gotham Rounded Light';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Rounded Light'), url("/static/media/Gotham Rounded Light.6a4f95c7.woff") format('woff');
}

@font-face {
  font-family: 'Gotham Rounded Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Rounded Medium'), url("/static/media/Gotham Rounded Medium.29c9cff4.woff") format('woff');
}

@font-face {
  font-family: 'Gotham Rounded Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Rounded Bold'), url("/static/media/Gotham Rounded Bold.640c207c.woff") format('woff');
}

@font-face {
  font-family: 'Heavy Equipment';
  font-style: normal;
  font-weight: normal;
  src: local('Heavy Equipment'), url("/static/media/Heavy Equipment.05d5d6a1.ttf") format('tff');
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .phone-warning {
    display: none;
  }

  #section-towing .lottie {
    margin-left: -20%;
    width: 120%;
  }
}

/* Small devices only (landscape phones, 576px and down) */
@media (max-width: 576px) {
  .section {
    padding-top: 100px !important;
    overflow-x: hidden;
  }

  .lottie {
    margin-top: 50px;
  }

  .intro-left-col {
    text-align: center;
    padding: 50px 30px;
  }

  .kickstarter-logo {
    height: 35px !important;
  }

  .number-of-cars {
    font-size: 40px !important;
  }

  .footer-links {
    margin-top: 30px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  #section-intro .container {
    margin-top: 25px;
  }

  .navbar.no-scroll {
    padding: 30px 50px;
    -webkit-transition: all 400ms;
    transition: all 400ms;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

body {
  margin: 0;
  padding: 0;
  color: #1e272c;
  font-family: "Gotham Rounded Light", "Helvetica", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn, .form-label {
  font-family: "Gotham Rounded Medium", "Helvetica", sans-serif !important;
}

.navbar.scroll {
  height: 70px;
  padding: 30px;
  background-color: #1e272c;
  -webkit-transition: all 400ms;
  transition: all 400ms;
}

.navbar.scroll img {
  height: 35px !important;
  margin-top: -3px;
  -webkit-transition: all 400ms;
  transition: all 400ms;
}

.navbar.scroll div {
  margin-top: -15px !important;
  -webkit-transition: all 400ms;
  transition: all 400ms;
}

.navbar.scroll div span {
  color: #FFF !important;
  font-size: large;
  -webkit-transition: all 400ms;
  transition: all 400ms;
}

.navbar.scroll .btn {
  padding: 5px 10px;
  -webkit-transition: all 400ms;
  transition: all 400ms;
}

/* Flash Animation */
.flash {
  color: #f2f;
  -webkit-animation: flash linear 1s infinite;
  animation: flash linear 1s infinite;
}

@-webkit-keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .1;
  }
  100% {
    opacity: 1;
  }
}

/* Vertical Move Animation */
.vertical-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

.vertical-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}

@keyframes mover {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}

