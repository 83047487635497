.download-link img {
  height: 60px;
  margin: 15px;
}

.download-link.disabled {
  opacity: 0.5 !important;
  cursor: no-drop;
}

.download-link:hover {
  opacity: 0.8;
}
